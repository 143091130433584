<template>
  <v-card>
    <v-card-title class="primary white--text"> Add Licences </v-card-title>
    <v-card-text class="mt-2">
      <div>
        <v-chip
        small
        color="primary"
        class="ml-1 mb-1"
        v-for="item in billingCounters"
        :key="item.id">
          {{item.counter}}</v-chip>
      </div>
      <p class="mt-1">
        If a license already exists, you must indicate an invoice number,
        otherwise a new invoice will be automatically created.
      </p>
      <v-row dense>
        <v-col cols="12" md="6">
          <billing-account-selector-autocomplete
            v-model="selectedBillingAccountLicence"
          />
        </v-col>
        <v-col cols="12" md="6">
          <fac-selector-autocomplete
            v-model="selectedFacLicence"
            :billingAccount="selectedBillingAccountLicence"
          />
        </v-col>
        <v-col cols="12" md="6">
          <menu-date
            label="Start licences"
            :clearable="true"
            v-model="licenceStart"
          />
        </v-col>
        <v-col cols="12" md="6" v-if="licenceStart">
          <v-select
            :items="[1, 12]"
            label="Duration (month)"
            v-model="period"
            prepend-icon="mdi-clock"
            suffix="month"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" v-if="licenceStart">
          <v-text-field
            v-model="price"
            prepend-icon="mdi-cash"
            label="Price (€ HT)"
            suffix="€ HT"
            type="number"
            min="0"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="licenceStart">
          <v-checkbox
            v-model="autoRenew"
            label="auto-renew Licence"
            color="primary"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="cancel" color="error">Cancel</v-btn>
      <v-btn @click="processing" color="success" :loading="loading">Add</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
import billingAccountSelectorAutocomplete from '../selectors/billingAccountSelectorAutocomplete.vue';
import facSelectorAutocomplete from '../selectors/facSelectorAutocomplete.vue';
import menuDate from '../Dates/menuDate.vue';
import billingCountersServices from '../../services/billingCounters.services';

export default {
  components: {
    billingAccountSelectorAutocomplete,
    facSelectorAutocomplete,
    menuDate,
  },
  props: {
    billingCounters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedBillingAccountLicence: 0,
      selectedFacLicence: 0,
      deliveryDate: moment().startOf('day').format('YYYY-MM-DD'),
      licenceStart: '',
      autoRenew: true,
      period: 12,
      price: 200,
      loading: false,
    };
  },
  methods: {
    reset() {
      this.selectedBillingAccountLicence = 0;
      this.selectedFacLicence = 0;
      this.deliveryDate = moment().startOf('day').format('YYYY-MM-DD');
      this.licenceStart = '';
      this.autoRenew = true;
      this.period = 12;
      this.price = 200;
      this.loading = false;
    },
    async createLicences({
      countersInvoices,
      start,
      period,
      price,
      autoRenew,
      customer,
      invoice,
    }) {
      const licences = countersInvoices.map((counterInvoice) => ({
        billing_counters: counterInvoice,
        start,
        period,
        customer,
        auto_renew: autoRenew,
        price,
        invoices: invoice
          ? [{
            // eslint-disable-next-line camelcase
            invoice: invoice?.user_code,
            customer,
            start,
            end: moment(start)
              .add(period, 'months')
              .format('YYYY-MM-DD'),
          }]
          : [],
      }));

      await billingCountersServices.addLicencesInvoices({
        licencesInvoices: licences,
      });

      return licences;
    },
    cancel() {
      this.reset();
      this.$emit('close');
    },
    processing() {
      this.loading = true;
      try {
        this.createLicences({
          countersInvoices: this.billingCounters.map((elem) => elem.id),
          start: this.licenceStart,
          period: this.period,
          price: this.price,
          autoRenew: this.autoRenew,
          customer: this.selectedBillingAccountLicence,
          // eslint-disable-next-line camelcase
          invoice: this.selectedFacLicence,
        });
        this.$emit('update');
        this.cancel();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
