<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatedDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :clearable="clearable"
        @click:clear="clearDate"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    birthdayMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      date: this.value,
      activePicker: null,
    };
  },
  watch: {
    value(val) {
      this.date = val;
    },
    date(val) {
      this.$emit('input', val);
    },
    menu(val) {
      if (val && this.birthdayMode) {
        setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR';
        });
      }
    },
  },
  computed: {
    formatedDate() {
      return this.date ? moment(this.date).format('L') : '';
    },
  },
  methods: {
    clearDate() {
      this.date = '';
    },
  },
};
</script>
