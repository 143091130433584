import Api from '@/services/Api';

export default {
  getBillingCounters() {
    return Api().get('oneup/billingCounters');
  },
  addCountersInvoice({ countersInvoices }) {
    return Api().post('oneup/billingCounters', countersInvoices);
  },
  addLicencesInvoices({ licencesInvoices }) {
    return Api().post('oneup/billingLicence', licencesInvoices);
  },
  savCounters({
    countersSav,
    date,
    changeInStructure,
    changeMachineAssociation,
    copyAlerts,
    comments,
  }) {
    return Api().post('oneup/savCounters', {
      countersSav,
      date,
      changeInStructure,
      changeMachineAssociation,
      copyAlerts,
      comments,
    });
  },
};
