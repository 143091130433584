<template>
  <v-autocomplete
    multiple
    label="Counters"
    item-text="id"
    item-value="id"
    chips
    deletable-chips
    :items="countersLight"
    v-model="selectedCounters"
  >
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: Array,
    default: () => [],
  },
  data() {
    return {
      selectedCounters: this.value,
    };
  },
  watch: {
    value(val) {
      this.selectedCounters = val;
    },
    selectedCounters(val) {
      this.$emit('input', val);
    },
  },
  computed: {
    ...mapGetters(['countersLight']),
  },
  created() {
    this.getCountersLight();
  },
  methods: {
    ...mapActions(['getCountersLight']),
  },
};
</script>
