<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="ma-2 filterWidth"
        small
        :color="filtereditems.length ? colorIsIn : undefined"
        outlined
        draggable
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small left> {{ icon }} </v-icon>
        {{ title }}
        <div class="textfilter">
          {{
            filtereditems.length
              ? `: ${filtereditems
                  .map((elem) => (text ? elem[text] : elem))
                  .join(", ")}`
              : ""
          }}
        </div>
        <v-icon small right> mdi-chevron-down </v-icon>
      </v-chip>
    </template>
    <v-card class="menuFilter" tile>
      <span class="text-caption mt-2 mx-2"
        >{{ title }}
        <b
          :class="`${colorIsIn}--text`"
          @click="isIn = !isIn"
          >{{ textIsIn }}</b
        >:</span
      >
      <div class="pa-2 allfilters">
        <v-chip
          small
          class="chipMaxWidth mb-1 mr-1"
          :color="colorIsIn"
          v-for="(item, index) in filtereditems"
          :key="index"
          @click="addOrRemove(item)"
        >
          <span class="textfilter">
            {{ text ? item[text] : item }}
          </span>

          <v-icon small right> mdi-close </v-icon>
        </v-chip>
      </div>
      <v-divider class="mx-1"></v-divider>
      <div>
        <v-text-field
          outlined
          hide-details
          dense
          height="22px"
          placeholder="search"
          class="mt-2 mb-1 mx-2 text-caption"
          v-model="search"
        ></v-text-field>
        <v-list dense class="pa-0 ma-0 listInfo">
          <v-list-item
            v-for="(item, index) in filtered"
            :key="index"
            class="py-0 pl-2 text-caption"
            @click="addOrRemove(item)"
          >
            <v-list-item-content>
              <v-list-item-title
                :class="filtereditems.includes(item) ? `${colorIsIn}--text` : ''"
                >{{ text ? item[text] : item }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import isEqual from 'lodash.isequal';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
    returnValue: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filtereditems: [],
      search: '',
      isIn: true,
    };
  },
  watch: {
    value: {
      handler(value) {
        if (this.returnValue && value.filters) {
          const filtered = [];

          value.filters.forEach((item) => {
            const tps = this.items.find(
              (elem) => elem[this.returnValue] === item,
            );
            if (tps) {
              filtered.push(tps);
            }
          });
          if (!isEqual(filtered, this.filtereditems)) {
            this.filtereditems = filtered;
          }
        } else {
          this.filtereditems = value.filters || [];
        }
      },
      immediate: true,
    },
    isIn() {
      const filters = this.returnValue
        ? this.filtereditems.map((elem) => elem[this.returnValue])
        : this.filtereditems;
      this.$emit('input', {
        filters,
        isIn: this.isIn,
      });
    },
    filtereditems: {
      handler(value) {
        const filters = this.returnValue
          ? value.map((elem) => elem[this.returnValue])
          : value;

        this.$emit('input', {
          filters,
          isIn: this.isIn,
        });
      },
      immediate: true,
    },
  },
  computed: {
    filtered() {
      return this.items.filter((item) => {
        const text = this.text ? item[this.text] : JSON.stringify(item);
        return text.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    textIsIn() {
      return this.isIn ? 'contains' : 'not contains';
    },
    colorIsIn() {
      return this.isIn ? 'success' : 'error';
    },
  },
  methods: {
    addOrRemove(item) {
      const index = this.filtereditems.indexOf(item);
      if (index === -1) {
        this.filtereditems.push(item);
      } else {
        this.filtereditems.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.filterWidth {
  max-width: 250px;
}

.chipMaxWidth {
  max-width: 145px;
}

.textfilter {
  text-overflow: ellipsis;

  overflow: hidden;
}

.menuFilterSearch {
  width: 100%;
}

.allfilters {
  min-height: 10px;
  max-width: 320px;
}

.listInfo {
  max-height: 190px;
  overflow-y: auto;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 320px;
  width: 320px;
}
</style>

<style>
.menuFilter .v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.menuFilter .v-list--dense .v-list-item .v-list-item__content {
  padding: 4px 0;
}

.menuFilter .v-list--dense .v-list-item {
  min-height: 25px;
}
</style>
