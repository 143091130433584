<template>
  <v-autocomplete
    label="Billing account"
    item-text="full_name"
    item-value="id"
    chips
    small-chips
    dense
    deletable-chips
    :items="account"
    v-model="selectedAccount"
    prepend-icon="mdi-refresh"
    @click:prepend="refreshAccount"
    :loading="loading"
  >
  </v-autocomplete>
</template>

<script>
import billingAccountServices from '../../services/billingAccount.services';

export default {
  data() {
    return {
      account: [],
      selectedAccount: '',
      loading: false,
    };
  },
  props: {
    value: Number,
  },
  mounted() {
    this.getAccount();
  },
  methods: {
    async getAccount() {
      const { data: accounts } = await billingAccountServices.getBillingAccount();
      this.account = accounts
        .sort((a, b) => (a.full_name.trim().toLowerCase() > b.full_name.trim().toLowerCase()
          ? 1
          : -1));
    },
    async refreshAccount() {
      this.loading = true;
      await billingAccountServices.refreshBillingAccount();
      this.loading = false;
      return this.getAccount();
    },
  },
  watch: {
    value(val) {
      this.selectedAccount = val;
    },
    selectedAccount(val) {
      this.$emit('input', val);
    },
  },
};
</script>
