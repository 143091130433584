<template>
  <v-card
    tile
    class="elevation-0">
    <v-card-title class="primary white--text"> Add Counters </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <counters-selector-autocomplete v-model="selectedCounters" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <h2>Counters Invoices</h2>
          </v-col>
          <v-col
            cols="12"
            md="6">
            <billing-account-selector-autocomplete v-model="selectedBillingAccount" />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <fac-selector-autocomplete
              v-model="selectedFac"
              :billingAccount="selectedBillingAccount" />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <menu-date
              label="Delivery date"
              v-model="deliveryDate" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <div class="d-flex">
              <h2>Licences</h2>
              <v-switch
                v-model="addlicence"
                color="success"
                class="pt-0 mt-0 mb-0 ml-2"></v-switch>
            </div>

            <p v-if="addlicence">
              If a license already exists, you must indicate an invoice number, otherwise a new
              invoice will be automatically created.
            </p>
          </v-col>
          <v-row
            dense
            v-if="addlicence">
            <v-col
              cols="12"
              md="6">
              <billing-account-selector-autocomplete v-model="selectedBillingAccountLicence" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <fac-selector-autocomplete
                v-model="selectedFacLicence"
                :billingAccount="selectedBillingAccountLicence" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <menu-date
                label="Start licences"
                :clearable="true"
                v-model="licenceStart" />
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="licenceStart">
              <v-select
                :items="[1, 12]"
                label="Duration (month)"
                v-model="period"
                prepend-icon="mdi-clock"
                suffix="month"></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="licenceStart">
              <v-text-field
                v-model="price"
                prepend-icon="mdi-cash"
                label="Price (€ HT)"
                suffix="€ HT"
                type="number"
                min="0"></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="licenceStart">
              <v-checkbox
                v-model="autoRenew"
                label="auto-renew Licence"
                color="primary"></v-checkbox>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="cancel"
        color="error"
        >Cancel</v-btn
      >
      <v-btn
        @click="processing"
        color="success"
        :loading="loading"
        >Add</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
import billingAccountSelectorAutocomplete from '../selectors/billingAccountSelectorAutocomplete.vue';
import countersSelectorAutocomplete from '../selectors/countersSelectorAutocomplete.vue';
import facSelectorAutocomplete from '../selectors/facSelectorAutocomplete.vue';
import menuDate from '../Dates/menuDate.vue';
import billingCountersServices from '../../services/billingCounters.services';

export default {
  components: {
    countersSelectorAutocomplete,
    billingAccountSelectorAutocomplete,
    facSelectorAutocomplete,
    menuDate,
  },
  data() {
    return {
      selectedCounters: [],
      selectedBillingAccount: 0,
      selectedBillingAccountLicence: 0,
      selectedFacLicence: 0,
      selectedFac: '',
      deliveryDate: moment().startOf('day').format('YYYY-MM-DD'),
      licenceStart: '',
      autoRenew: true,
      period: 12,
      price: 200,
      addlicence: false,
      loading: false,
    };
  },
  methods: {
    reset() {
      this.selectedCounters = [];
      this.selectedBillingAccount = 0;
      this.selectedBillingAccountLicence = 0;
      this.selectedFacLicence = 0;
      this.selectedFac = '';
      this.deliveryDate = moment().startOf('day').format('YYYY-MM-DD');
      this.licenceStart = '';
      this.autoRenew = true;
      this.period = 12;
      this.price = 200;
      this.addlicence = false;
      this.loading = false;
    },
    cancel() {
      this.$emit('close');
      this.reset();
    },
    async createCounterInvoices({ counters, customer, invoice, startDate }) {
      const { data: counterInvoice } = await billingCountersServices.addCountersInvoice({
        countersInvoices: {
          counters,
          customer,
          invoice,
          startDate,
        },
      });

      return counterInvoice;
    },
    async createLicences({ countersInvoices, start, period, price, autoRenew, customer, invoice }) {
      const licences = countersInvoices.map((counterInvoice) => ({
        billing_counters: counterInvoice,
        start,
        period,
        customer,
        auto_renew: autoRenew,
        price,
        invoices: invoice
          ? [
              {
                // eslint-disable-next-line camelcase
                invoice: invoice?.user_code,
                customer,
                start,
                end: moment(start).add(period, 'months').format('YYYY-MM-DD'),
              },
            ]
          : [],
      }));

      await billingCountersServices.addLicencesInvoices({
        licencesInvoices: licences,
      });

      return licences;
    },
    async processing() {
      this.loading = true;

      try {
        const countersInvoices = await this.createCounterInvoices({
          counters: this.selectedCounters,
          customer: this.selectedBillingAccount,
          // eslint-disable-next-line camelcase
          invoice: this.selectedFac?.user_code,
          startDate: this.deliveryDate,
        });

        if (this.addlicence && this.licenceStart) {
          await this.createLicences({
            countersInvoices: countersInvoices.map((counterInvoice) => counterInvoice.id),
            start: this.licenceStart,
            period: this.period,
            price: this.price,
            autoRenew: this.autoRenew,
            customer: this.selectedBillingAccountLicence,
            // eslint-disable-next-line camelcase
            invoice: this.selectedFacLicence,
          });
        }

        this.$emit('update');
        this.cancel();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    selectedFac(val) {
      if (val && val.date) {
        this.deliveryDate = moment(val.date).format('YYYY-MM-DD');
      }
    },
    selectedBillingAccount(val) {
      if (!this.selectedBillingAccountLicence) {
        this.selectedBillingAccountLicence = val;
      }
    },
  },
};
</script>

<style scoped>
</style>
