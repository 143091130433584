import Api from '@/services/Api';

export default {
  getAllFac(customerId) {
    return Api().get('oneup/invoices', { params: { customerId: customerId || undefined } });
  },
  refreshInvoices(customerId) {
    return Api().get('oneup/refreshInvoices', { params: { customerId: customerId || undefined } });
  },
};
