<template>
  <div>
    <div>
      <input
        type="text"
        class="searchInput"
        placeholder="Search..."
        v-model="search" />
      <listFilters
        :items="billingAccount"
        text="full_name"
        returnValue="id"
        title="Billing Account"
        icon="mdi-account-box-multiple"
        v-model="filtereditems" />
    </div>
    <div class="px-2 mb-2 actionBtn">
      <template v-if="selected.length">
        <v-btn
          x-small
          color="primary"
          @click="dialogAddLicence = true"
          >Add licence ({{ selected.length }} counters)</v-btn
        >
        <v-btn
          class="ml-1"
          x-small
          color="primary"
          @click="dialogSav = true"
          >SAV ({{ selected.length }} counters)</v-btn
        >
      </template>
    </div>

    <v-data-table
      :headers="headers"
      :items="countersFiltered"
      :items-per-page="-1"
      show-select
      v-model="selected"
      item-key="id"
      hide-default-footer>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="seeDetails(item)">
          mdi-information
        </v-icon>
      </template>
    </v-data-table>
    <v-btn
      color="success"
      dark
      small
      rounded
      fab
      @click="openDialogAdd"
      class="bottom-btn">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="dialogCounterDetails"
      absolute
      temporary
      width="650px"
      right>
      {{ selectedForDetails }}
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="dialogAdd"
      absolute
      temporary
      width="650px"
      right>
      <add-invoices
        @close="dialogAdd = false"
        @update="refreshBillingCounters"
        v-if="dialogAdd" />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="dialogAddLicence"
      absolute
      temporary
      width="650px"
      right>
      <add-licence
        :billingCounters="selected"
        @close="dialogAddLicence = false"
        @update="refreshBillingCounters"
        v-if="dialogAddLicence" />
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="dialogSav"
      absolute
      temporary
      width="650px"
      right>
      <sav-counters
        :billingCounters="selected"
        @close="dialogSav = false"
        @update="refreshBillingCounters"
        v-if="dialogSav" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import addInvoices from '../../components/invoices/addInvoices.vue'
import addLicence from '../../components/invoices/addLicences.vue'
import savCounters from '../../components/invoices/savCounters.vue'
import billingCountersServices from '../../services/billingCounters.services'
import listFilters from '../../components/filters/listFilters.vue'
import billingAccountServices from '../../services/billingAccount.services'

export default {
  components: {
    addInvoices,
    listFilters,
    addLicence,
    savCounters,
  },
  data() {
    return {
      dialogAdd: false,
      dialogAddLicence: false,
      dialogSav: false,
      dialogCounterDetails: false,
      tab: null,
      selected: [],
      selectedForDetails: {},
      billingCounters: [],
      headers: [
        {
          text: 'Counter',
          align: 'start',
          sortable: true,
          value: 'counter',
        },
        { text: 'Billing Account', value: 'customer' },
        // { text: 'Invoices', value: 'invoice' },
        { text: 'Licence', value: 'licence' },
        { text: 'Licence Owner', value: 'licenceCustomer' },
        { text: 'Renew', value: 'autoRenew' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      counters: [],
      billingAccount: [],
      filtereditems: { filters: [], isIn: true },
      search: '',
    }
  },
  computed: {
    countersFiltered() {
      const now = new Date().toISOString()

      return this.billingCounters
        .filter((elem) => {
          if (this.filtereditems.filters.length) {
            return this.filtereditems.filters.includes(elem.customer) === this.filtereditems.isIn
          }
          return true
        })
        .map((elem) => {
          const currentCounter = elem.counters.find((counter) => {
            if (counter.start < now && !(counter.end < now)) {
              return true
            }
            return false
          })

          const customer = this.billingAccount.find((billingAccount) => {
            if (billingAccount.id === elem.customer) {
              return true
            }
            return false
          })

          const licenceCustomer = elem.licences?.customer
            ? this.billingAccount.find((billingAccount) => {
                if (billingAccount.id === elem?.licences?.customer) {
                  return true
                }
                return false
              })
            : undefined

          const licenceLast = elem?.licences?.invoices.sort((a, b) => (a.end < b.end ? 1 : -1))[0]

          const nextInvoice = licenceLast?.end.split('T')[0] || elem.licences?.start.split('T')[0]

          return {
            counter: currentCounter?.counter,
            // eslint-disable-next-line camelcase
            customer: `${customer?.full_name} (${elem.invoices})`,
            invoice: elem.invoices,
            id: elem.id,
            // eslint-disable-next-line camelcase
            licenceCustomer: licenceCustomer?.full_name,
            licence: licenceLast
              ? `${licenceLast?.start.split('T')[0]} => ${licenceLast?.end.split('T')[0]} (${
                  licenceLast?.invoice
                })`
              : undefined,
            periodLicence: elem.licences ? `${elem.licences?.period} months` : '',
            // eslint-disable-next-line camelcase
            autoRenew: elem.licences?.auto_renew
              ? `${nextInvoice} for ${elem.licences?.period} months`
              : 'No',
          }
        })
        .filter((elem) => {
          if (this.search) {
            return JSON.stringify(elem).toLowerCase().includes(this.search.toLowerCase())
          }
          return true
        })
    },
  },
  methods: {
    seeDetails(item) {
      this.selectedForDetails = item
      this.dialogCounterDetails = true
    },
    openDialogAdd() {
      this.dialogAdd = true
    },
    async refreshBillingCounters() {
      const { data: billingCountersTps } = await billingCountersServices.getBillingCounters()
      this.billingCounters = billingCountersTps
    },
    async refreshBillingAccount() {
      const { data: tps } = await billingAccountServices.getBillingAccount()
      this.billingAccount = tps.sort((a, b) => {
        const isAgteB = a.full_name.trim().toLowerCase() > b.full_name.trim().toLowerCase() ? 1 : -1

        return isAgteB
      })
    },
  },
  async mounted() {
    await this.refreshBillingAccount()
    await this.refreshBillingCounters()
  },
}
</script>

<style scoped>
.bottom-btn {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.actionBtn {
  min-height: 24px;
}

.searchInput {
  border-radius: 12px;
  border-width: thin;
  border-style: solid;
  padding: 11px;
  margin: 3px;
  width: 180px;
  height: 15px;
  font-size: 12px;
}
</style>
