import Api from '@/services/Api';

export default {
  getBillingAccount() {
    return Api().get('oneup/customers');
  },
  refreshBillingAccount() {
    return Api().get('oneup/refreshCustomers');
  },
};
