<template>
  <v-card
    tile
    class="elevation-0">
    <v-card-text>
      <v-container>
        <v-row dense>
          <v-col
            xs="12"
            md="6">
            <menu-date
              label="SAV Date"
              :clearable="true"
              v-model="savDate" />
          </v-col>
        </v-row>
        <v-row
          dense
          class="my-0 py-0"
          v-for="(item, index) in billingCounters"
          :key="item.id"
          justify="center">
          <v-col align-self="center">
            <b>{{ item.counter }}</b>
          </v-col>
          <v-col align-self="center">
            <v-icon>mdi-arrow-right-bold-outline</v-icon>
          </v-col>
          <v-col align-self="center">
            <counter-selector-autocomplete v-model="selectedCounters[index]" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-switch
              v-model="changeInStructure"
              label="Change counter in cluster"></v-switch>
          </v-col>
          <v-col>
            <v-switch
              v-model="changeMachineAssociation"
              label="Change machine association"></v-switch>
          </v-col>
          <v-col>
            <v-switch
              v-model="copyAlerts"
              label="Copy user alerts"></v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col xs="12">
            <v-textarea
              label="Comments"
              outlined
              v-model="comments"></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="cancel"
        color="error"
        >Cancel</v-btn
      >
      <v-btn
        :disabled="!isValide"
        @click="processing"
        color="success"
        :loading="loading"
        >OK</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
import counterSelectorAutocomplete from '../selectors/counterSelectorAutocomplete.vue';
import menuDate from '../Dates/menuDate.vue';
import billingCountersServices from '../../services/billingCounters.services';

export default {
  components: {
    counterSelectorAutocomplete,
    menuDate,
  },
  props: {
    billingCounters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedCounters: [],
      savDate: moment().startOf('day').format('YYYY-MM-DD'),
      changeInStructure: true,
      changeMachineAssociation: true,
      copyAlerts: true,
      loading: false,
      comments: '',
    };
  },
  computed: {
    isValide() {
      const nonNullValuesInSelectedCounters = !this.selectedCounters.includes(undefined);

      const sameSize = this.selectedCounters.length === this.billingCounters.length;

      return nonNullValuesInSelectedCounters && sameSize;
    },
  },
  methods: {
    reset() {
      this.selectedCounters = [];
    },
    cancel() {
      this.reset();
      this.$emit('close');
    },
    async processing() {
      this.loading = true;
      try {
        const payload = {
          countersSav: this.selectedCounters.map((counter, index) => ({
            billingCounterId: this.billingCounters[index].id,
            newCounter: counter,
          })),
          date: moment(this.savDate).startOf('day').utc().toISOString(),
          changeInStructure: this.changeInStructure,
          changeMachineAssociation: this.changeMachineAssociation,
          copyAlerts: this.copyAlerts,
          comments: this.comments,
        };

        await billingCountersServices.savCounters(payload);

        this.$emit('update');
        this.cancel();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
