<template>
  <div>
    <v-tabs
      centered
      grow
      background-color="secondary"
      dark
      color="yellow">
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        exact
        :to="tab.url"
        >{{ tab.name }}</v-tab
      >
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          name: 'Counters',
          url: '/invoicing/counters',
        },
        {
          name: 'Billing Accounts',
          url: '/invoicing/billingAccounts',
        },
      ],
    }
  },
}
</script>
