<template>
  <v-autocomplete
    label="Invoice"
    item-text="user_code"
    return-object
    chips
    small-chips
    dense
    deletable-chips
    :items="facs"
    v-model="selectedFac"
    prepend-icon="mdi-refresh"
    @click:prepend="refreshInvoices"
    :loading="loading"
  >
  </v-autocomplete>
</template>

<script>
import facServices from '../../services/fac.services';

export default {
  props: ['billingAccount', 'value'],
  data() {
    return {
      facs: [],
      selectedFac: {},
      loading: false,
    };
  },
  mounted() {
    this.getFacs();
  },
  watch: {
    billingAccount() {
      this.getFacs();
    },
    value(val) {
      this.selectedFac = val;
    },
    selectedFac(val) {
      this.$emit('input', val);
    },
  },
  methods:
  {
    async getFacs() {
      const { data: lesFacs } = await facServices.getAllFac(this.billingAccount);
      this.facs = lesFacs;
    },
    async refreshInvoices() {
      this.loading = true;
      await facServices.refreshInvoices(this.billingAccount);
      this.loading = false;
      return this.getFacs();
    },
  },
};

</script>
